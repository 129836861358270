import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable, signal } from "@angular/core";
import { environment } from "../../../environments/environment";
import { map, of, tap } from "rxjs";
import { IAddress } from "../../models/address";
import { IPlaceAutoComplete } from "../../models/placeAutoComplete";
import { IGeocode } from "../../models/gecode";

@Injectable({
    providedIn: 'root'
})
export class AddressService {

    private teamAdvanceAddressApi = environment.enforceApiUrl;
    private options = { headers: new HttpHeaders().set('Content-Type', 'application/json'), params: new HttpParams() };
    constructor(private _httpClient: HttpClient) {
    }
    public states = ['Alabama',
        'Alaska',
        'Arizona',
        'Arkansas',
        'California',
        'Colorado',
        'Connecticut',
        'Delaware',
        'Florida',
        'Georgia',
        'Hawaii',
        'Idaho',
        'Illinois',
        'Indiana',
        'Iowa',
        'Kansas',
        'Kentucky',
        'Louisiana',
        'Maine',
        'Maryland',
        'Massachusetts',
        'Michigan',
        'Minnesota',
        'Mississippi',
        'Missouri',
        'Montana',
        'Nebraska',
        'Nevada',
        'New Hampshire',
        'New Jersey',
        'New Mexico',
        'New York',
        'North Carolina',
        'North Dakota',
        'Ohio',
        'Oklahoma',
        'Oregon',
        'Pennsylvania',
        'Rhode Island',
        'South Carolina',
        'South Dakota',
        'Tennessee',
        'Texas',
        'Utah',
        'Vermont',
        'Virginia',
        'Washington',
        'West Virginia',
        'Wisconsin',
        'Wyoming', ];

    placeAutoComplete(input: string) {
        let params = new HttpParams()
            .set('input', input)
        this.options.params = params
        return this._httpClient.get<IPlaceAutoComplete[]>(this.teamAdvanceAddressApi + "address/api/v1/" + "place/autocomplete?", this.options);
    }

    placeGeoCode(geocode: { input: string, type: string }, index:number) {
        let params = new HttpParams()
            .set('input', geocode.input)
            .append('type', geocode.type)
        this.options.params = params
        return this._httpClient.get<IAddress[]>(this.teamAdvanceAddressApi + "address/api/v1/" + "place/geocode/" + index, this.options)
    }

    placeGeoCodes(gecodes: IGeocode[]) {
        return this._httpClient.post<Map<string, IAddress[]>>(this.teamAdvanceAddressApi + "address/api/v1/" + "place/geocode", gecodes, this.options)
    }



} 