import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { IEmployee } from "../../models/employee";
import { IGeocode } from "../../models/gecode";
import { IAddress } from "../../models/address";

export const EmployeePageActions = createActionGroup({
    source: 'Employee Page',
    events: {
        'Load Employees': emptyProps(),
        'Selected Employee': props<{employeeId:string}>(),
        'Add Employee': props<{employee:IEmployee}>(),
        'Update Employee': props<{employee: IEmployee}>(),
        'Delete Confirmation': emptyProps(),
        'Delete Yes':emptyProps(),
        'Delete No': emptyProps(),
        'Delete Employee': props<{employeeId:string}>(),
        'Add Employees': props<{employees: IEmployee[]}>()
    }
}
)
export const EmployeesAPIActions = createActionGroup({
    source: 'Employee API ',
    events:{
        'Employees Loaded Success' : props<{employees:IEmployee[]}>(),
        'Employees Loaded Fail': props<{ message: string }>(),
        'Employee By ID Success': props<{employee : IEmployee}>(),
        'Employee By ID Fail': props<{message:string}>(),
        'Employee Add Success': props<{employee:IEmployee}>(),
        'Employee Add Fail': props<{message:string}>(),
        'Employees Add Success': props<{employees:IEmployee[]}>(),
        'Employees Add Fail': props<{message:string}>(),
        'Employee Update Success': props<{employee:IEmployee}>(),
        'Employee Update Fail': props<{message:string}>(),
        'Employee Delete Success': props<{employeeId:string}>(),
        'Employee Delete Fail': props<{message:string}>()
    }
})
